import React, { useContext } from "react";
import TabPanel from "./components/TabPanel";
import GeneralInfoContent from "./components/GeneralInfoContent";
import AutoAssignment from "./components/AutoAssignment/AutoAssignment";
import Organization from "./components/Organization";
import DataContext from "../../../../../../context/DataContext";

function UserOptions({ userInfo, trigger, setTrigger, myAccount }) {
  const { userRoles } = useContext(DataContext);

  const allTabs = [
    {
      name: "General Info",
      isDisabled: false,
      component: (
        <GeneralInfoContent
          userInfo={userInfo}
          trigger={trigger}
          setTrigger={setTrigger}
          myAccount={myAccount}
        />
      ),
      permission: null,
    },
    {
      name: "Auto Assignment",
      isDisabled: false,
      component: <AutoAssignment userId={userInfo.pk} />,
      permission: "users.add_team",
    },
    {
      name: "Organization",
      isDisabled: false,
      component: <Organization userId={userInfo.pk} trigger={trigger} setTrigger={setTrigger} />,
      permission: null,
    },
    {
      name: "Permissions",
      isDisabled: true,
      component: <></>,
      permission: null,
    },
    {
      name: "Settings",
      isDisabled: true,
      component: <></>,
      permission: null,
    },
  ];
  const tabList = [];

  const generateTabList = () => {
    allTabs.forEach((tab) => {
      if (tab.permission === null) {
        tabList.push(tab);
      }
      if (
        tab.permission !== null &&
        userRoles?.permissions?.includes(tab.permission)
      ) {
        tabList.push(tab);
      }
    });

    return tabList;
  };

  return (
    <div className="h-full bg-white shadow-lg p-4 xl:mr-6 mt-6 rounded-sm">
      <TabPanel tabList={generateTabList()}/>
    </div>
  );
}

export default UserOptions;
