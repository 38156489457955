import TabHeader from "./TabHeader";
import TabBody from "./TabBody";
import { useState } from "react";

export default function TabPanel({ tabList }) {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="">
      <TabHeader
        tabList={tabList}
        tabValue={tabValue}
        handleChange={handleChange}
      />
      <TabBody tabList={tabList} tabValue={tabValue} style={{minHeight:'500px '}} />
    </div>
  );
}
