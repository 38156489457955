import TabPanel from "../../../ClientDetail/TabClientNotesCallLog/TabPanel";

export default function TabBody({ tabList, tabValue }) {
  return (
    <>
      {tabList.map((tab, index) => (
        <TabPanel
          key={"tabpanel_" + index}
          value={tabValue}
          index={index}     
          className=""
               
        >
          {tab.component}
        </TabPanel>
      ))}
    </>
  );
}
