import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../../../../../../../global/TabPanel";
import LawFirmTeams from "./LawFirmTeams";
import Capacities from "./Capacities/Capacities";

const AutoAssignment = ({userId}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="Auto-Assignment" data-testid="Auto-Assignment" className="py-6 h-full">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="auto-assignment tabs"
        TabIndicatorProps={{ sx: { backgroundColor: "#9333EA" } }}
        sx={{
          "& button.Mui-selected": {
            color: `#9333EA`,
          },
          ".Mui-selected": {
            color: `#9333EA`,
          },
          ".MuiBox-root.div": {
            padding: 0,
          },
        }}
      >
        <Tab label="Law Firm Teams" />
        <Tab label="Capacities" />
        <Tab label="Unavailability" disabled />
      </Tabs>
      <TabPanel value={value} index={0}  >
        <LawFirmTeams userId={userId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Capacities userId={userId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* Future Unavailability Panel Content */}
      </TabPanel>
    </div>
  );
};

export default AutoAssignment;
