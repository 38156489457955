import React, {useContext, useEffect, useState} from "react";
import {Button} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import axios from "../../../../../../../api/axios";
import DataContext from "../../../../../../../../context/DataContext";
import AddUserModal from "./AddUserModal";
import ResponseModal from "../../../../../../../global/ResponseModal";
import ConfirmationModal from "../../../../../../../global/Modal/ConfirmationModal";


const columns = [
  {
    field: 'name', headerName: 'User Name', sortable: false, flex: 1, renderCell: (params) => {
      return <div>{params.row?.user?.name}</div>;
    }
  },
  {
    field: 'email', headerName: 'User Email', sortable: false, flex: 1, renderCell: (params) => {
      return <div>{params.row?.user?.email}</div>;
    }
  },
  {
    field: 'phone', headerName: 'User Phone', sortable: false, flex: 1, renderCell: (params) => {
      return <div>{params.row?.user?.phone}</div>;
    }
  },
  {
    field: 'address', headerName: 'User Address', sortable: false, flex: 1, renderCell: (params) => {
      return <div>{params.row?.user?.address?.formatted}</div>;
    }
  },
];


const Organization = ({userId, trigger: globalTrigger, setTrigger: setGlobalTrigger}) => {
  const {accessToken, userRoles} = useContext(DataContext);
  const [managedUsers, setManagedUsers] = useState([]);
  const [allManagedUsers, setAllManagedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [isAddUserModalVisible, setAddUserModalVisibility] = useState(false);
  const [responseState, setResponseState] = useState({
    message: "",
    isError: false
  });
  const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25
  });
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedUsersForDeletion, setSelectedUsersForDeletion] = useState([]);

  useEffect(() => {
    if (!userRoles?.permissions?.includes("users.view_usertomanagerassignments") || !userId)
      return


    setLoading(true);
    const offset = paginationModel.page * paginationModel.pageSize;
    axios.get(`/api/user_manager/?managed_by=${userId}&ordering=user_name&limit=${paginationModel.pageSize}&offset=${offset}`, {
      headers: {Authorization: `Token ${accessToken}`},
    })
      .then((response) => {
        setManagedUsers(response.data.results);
        setTotalRowCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setResponseState({message: "There was an error loading this content", isError: true});
      });

    axios.get(`/api/user_manager/?managed_by=${userId}&ordering=user_name&limit=9999`, {
      headers: {Authorization: `Token ${accessToken}`},
    })
      .then((response) => {
        setAllManagedUsers(response.data.results);
      })
      .catch((error) => {
      });

  }, [userId, accessToken, userRoles, globalTrigger, paginationModel]);

  const handleRowSelectionChange = (newRowSelectionModel) => {
    const newlySelectedUsers = managedUsers.filter(user => newRowSelectionModel.includes(user.pk));
    const updatedSelectedUsers = [...selectedUsersForDeletion, ...newlySelectedUsers];

    setSelectedUsersForDeletion(updatedSelectedUsers);
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleDeleteConfirmation = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    const entityIds = selectedUsersForDeletion.map(user => user.pk);

    if (entityIds.length === 0) {
      return;
    }

    axios
      .post('/api/user_manager/bulk_delete/', entityIds, {
        headers: {Authorization: `Token ${accessToken}`},
      })
      .then(() => {
        setManagedUsers(prevUsers =>
          prevUsers.filter(user => !entityIds.includes(user.pk))
        );
        setResponseState({
          description: "Users have been deleted",
          isError: false
        });
        setIsResponseModalVisible(true)
        setGlobalTrigger(prev => !prev);
        setShowConfirmDeleteModal(false);
        setRowSelectionModel([]);
      })
      .catch((error) => {
        setResponseState({
          description: 'There was an error removing users from your team',
          isError: true
        });
        setIsResponseModalVisible(true)
      });
  };

  return (
    <div
      id="User-Management-Organization"
      data-testid="User-Management-Organization"
    >

      <div id="Law-Firm-Teams" data-testid="Law-Firm-Teams" className="my-2">
        <div className="flex items-center">
          <div className="w-[50%]">
            <span className="text-xl">Managed Users</span>
          </div>
          <div className="w-[50%] flex justify-end">
            {rowSelectionModel.length > 0 && (<Button
              className="m-2 capitalize"
              variant="contained"
              color="error"
              onClick={() => handleDeleteConfirmation(rowSelectionModel)}
            >
              Delete User
            </Button>)}
            <Button
              className="m-2 capitalize"
              variant="outlined"
              onClick={() => setAddUserModalVisibility(true)}
            >
              Add Managed User
            </Button>
          </div>
        </div>
        <DataGrid
          rows={managedUsers}
          loading={loading}
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          checkboxSelection
          initialState={{pagination: {paginationModel}}}
          getRowId={(row) => row.pk}
          sx={{border: 0}}
          localeText={{noRowsLabel: "No Managed Users"}}
          onRowSelectionModelChange={handleRowSelectionChange}
          onPaginationModelChange={setPaginationModel}
          rowSelectionModel={rowSelectionModel}
          pageSizeOptions={[25]}
          pagination
          paginationMode="server"
          rowCount={totalRowCount}
          keepNonExistentRowsSelected

        />


      </div>
      {isAddUserModalVisible && (
        <AddUserModal
          currentUserUserId={userId}
          handleClose={() => setAddUserModalVisibility(false)}
          managedUsers={allManagedUsers}
          setTrigger={setGlobalTrigger}
          setResponseState={setResponseState}
          setIsResponseModalVisible={setIsResponseModalVisible}
        />
      )}
      <ResponseModal
        title={responseState.isError ? "Failed" : "Successful"}
        isError={responseState.isError}
        description={responseState.description}
        errorMessage={responseState.description}
        openBool={isResponseModalVisible}
        setOpenBool={setIsResponseModalVisible}
      />
      <ConfirmationModal
        open={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete the selected user(s)?"
      />

    </div>

  );
};

export default Organization;
