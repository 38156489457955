import React, { useContext, useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  LawFirmsDispatchContext,
  LawFirmsStateContext,
} from "../context/LawFirmsProvider";
import { setOrderingBy, setOrderingDirection } from "../state/actions";
import Colors from "../../../../../../../../../global/Colors";
import { setSelectedTeams } from "../state/actions";

const AssignedLawFirms = ({ showDeleteButton }) => {
  const state = useContext(LawFirmsStateContext);
  const dispatch = useContext(LawFirmsDispatchContext);
  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setSelectedTeams(dispatch, newChecked);

    let shouldShowDelete = newChecked.length > 0 ? true : false;
    showDeleteButton(shouldShowDelete);
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      state.orderingBy === property && state.orderingDirection === "asc";
    setOrderingDirection(dispatch, isAsc ? "desc" : "asc");
    setOrderingBy(dispatch, property);
  };

  const FormatPhoneNumber = (phoneNumberString) => {
    if (phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
    }
    return " ";
  };
  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {state.columns &&
            Object.keys(state.columns).length > 0 &&
            Object.keys(state.columns).map((col) => (
              <TableCell
                key={state.columns[col].field_name}
                sortDirection={
                  state.orderingBy === state.columns[col].field_name
                    ? state.orderingDirection
                    : false
                }
              >
                <div className="font-bold text-center">
                  {state.columns[col].display}
                </div>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };
  const createColumns = (cell, rowIndex) => {
    let fieldName = cell.team.law_firm;
    return (
      <TableRow key={rowIndex + "_" + cell.pk} className="py-0">
        <TableCell
          key={fieldName + "_" + cell.pk}
          sx={{ border: 1, borderColor: Colors.slate[300] }}
          className="py-0 w-[5%]"
        >
          <Checkbox
            edge="start"
            checked={checked.includes(cell)}
            tabIndex={-1}
            onClick={handleToggle(cell)}
            disableRipple
            inputProps={{ "aria-labelledby": fieldName.pk }}
          />
        </TableCell>
        <TableCell
          key={fieldName + "_name_" + cell.pk}
          sx={{ border: 1, borderColor: Colors.slate[300] }}
          className="py-0 w-[25%]"
        >
          <div>{fieldName.name}</div>
        </TableCell>
        <TableCell
          key={fieldName + "_email_" + cell.pk}
          sx={{ border: 1, borderColor: Colors.slate[300] }}
          className="py-0 w-[25%] text-center whitespace-nowrap"
        >
          <div>{fieldName.email}</div>
        </TableCell>
        <TableCell
          key={fieldName + "_phone_" + fieldName.pk}
          sx={{ border: 1, borderColor: Colors.slate[300] }}
          className="py-0 w-[15%] text-center whitespace-nowrap"
        >
          <div>{FormatPhoneNumber(fieldName.phone)}</div>
        </TableCell>
        <TableCell
          key={fieldName + "_address_" + fieldName.pk}
          sx={{ border: 1, borderColor: Colors.slate[300] }}
          className="py-0 w-[30%] text-center"
        >
          <div>{fieldName.address}</div>
        </TableCell>
      </TableRow>
    );
  };
  const isAnyTableDataExist =
    state.data &&
    state.data.length > 0 &&
    state.columns &&
    state.columns.length > 0;
  return (
    <div id="Report-Table" data-id="Report-Table" className="my-2">
      {isAnyTableDataExist ? (
        <TableContainer className="overflow-auto max-h-[50vh]">
          <Table stickyHeader size="small">
            <TableHeader
              order={state.orderingDirection}
              orderBy={state.orderingBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {state.data &&
                state.data.map((rows, idx) => createColumns(rows, idx))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "No Data"
      )}
    </div>
  );
};

export default AssignedLawFirms;
