import React from "react";
import LawFirmTeamsWithState from "./LawFirmTeamsWithState";
import LawFirmsProvider from "./context/LawFirmsProvider";

const LawFirmTeams = ({userId}) => {
  return (
    <div id="Law-Firm-Teams" data-testid="Law-Firm-Teams">
      <LawFirmsProvider>
        <LawFirmTeamsWithState userId={userId} />
      </LawFirmsProvider>
    </div>
  );
};

export default LawFirmTeams;
