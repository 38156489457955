import { Tabs, Box, Tab } from "@mui/material";

export default function TabHeader({ tabList, tabValue, handleChange }) {
  return (
    <Box
    className="justify-between bg-white"

      sx={{
        borderBottom: 1,
        borderColor: "divider",
        padding: "0",
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        className="justify-between"
        variant="fullWidth"
        TabIndicatorProps={{ sx: { backgroundColor: "#9333EA" } }}
        sx={{
          "& button.Mui-selected": {
            color: `#9333EA`,
          },
          ".Mui-selected": {
            color: `#9333EA`,
          },
          ".MuiBox-root.div": {
            padding: 0,
          },
        }}
      >
        {tabList.map((tab, index) => {
          return (
            <Tab
              key={tab.id + '_' + index}
              label={tab.name}
              className={"w-1/2"}
              value={index}
              disabled={tab.isDisabled}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
